<template>
  <div>
    <transition name="fade">
      <forgot-password-modal ref="modalForgotPassword" v-show="forgotPassword" @closeModal="forgotPassword = false"
        color="primary" />
    </transition>
    <div class="wrapper">
      <div class="image"></div>
      <div class="article">
        <div class="article_content">
          <p class="title">Vantagens para sua empresa</p>
          <p class="subtitle">Taxas de Juro</p>
          <p class="text">Taxas de juros até 10x menores do que o mercado* (a partir de 1,9% a.m)</p>
          <p class="subtitle">Sem garantia real</p>
          <p class="text">Necessário somente avalista</p>
          <p class="subtitle">Análise rápida</p>
          <p class="text">Retorno da sua solicitação em até 48h</p>
          <p class="subtitle">Acesso facilitado</p>
          <p class="text">Sem burocracia e toda contratação online</p>
          <p class="subtitle">Transparência</p>
          <p class="text">Saiba exatamente quanto está pagando</p>
        </div>
      </div>
      <div class="form_section container">
        <div class="form_content">
          <div class="logo" @click="$router.push('/')"></div>
          <div v-if="this.$route.query.bearerToken && !this.$route.query.user" class="form_title">Confirmando
            autenticação remota de administrador, aguarde...</div>
          <div v-if="this.$route.query.bearerToken && this.$route.query.user" class="form_title">Confirmando
            autenticação e redirecionando para o painel, aguarde...</div>
          <div v-if="!this.$route.query.bearerToken" class="form_title">Faça login e acompanhe suas solicitações de
            empréstimos</div>
          <form v-if="!this.$route.query.bearerToken" @submit.prevent="login" @keyup.enter="login"
            class="w-full flex flex-col">
            <v-input v-if="$route.query.administrador" name="adminEmail" v-lowercase v-trim v-model="form.adminEmail"
              :error="errors.first('adminEmail')" label="E-mail de Administrador" v-validate="'required'"></v-input>
            <v-input name="email" v-lowercase v-trim v-model="form.email" :error="errors.first('email')" label="E-mail"
              v-validate="'required|email'"></v-input>
            <v-input name="password" type="password" ref="password" v-model="form.password"
              :error="errors.first('password')" label="Senha" v-validate="'required'"></v-input>
            <v-input v-if="isPartner" class="w-full" name="cnpj" v-model="form.cnpj" :error="errors.first('cnpj')"
              label="CNPJ*" v-validate="'required|cnpj'" mask="##.###.###/####-##"></v-input>
            <div class="flex justify-between">
              <p class="pass_recovery" @click="isPartner = !isPartner">{{ isPartner ? 'Sou tomador' : `Sou sócio/
                              avalista`}}</p>
              <p class="pass_recovery" @click="showModalForgotPassord">Esqueci minha senha</p>
            </div>
            <v-button color="primary" class="self-end" :loading="loading">Entrar</v-button>
          </form>
        </div>
        <small style="color: #333; font-size: 0.6em"> {{ new Date() }}</small>

        <div class="footer">
          Copyright © TuTu Digital. Todos os Direitos Reservados.
          Tutu Digital Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida
          Paulista, 1374 - Andar 11, São Paulo, SP. CEP 01310-100.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ForgotPasswordModal from '@/components/Modal/ForgotPasswordModal'
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'
import Login from '@/services/Landing/Login'
import LoginBearer from '@/services/Commons/LoginBearer'

export default {
  components: { ForgotPasswordModal, VInput, VButton },
  data() {
    return {
      forgotPassword: false,
      loading: false,
      isPartner: false,

      form: {
        adminEmail: '',
        email: '',
        password: '',
        cnpj: ''
      }
    }
  },
  methods: {
    showModalForgotPassord() {
      this.forgotPassword = true;
      this.$refs.modalForgotPassword.setEmail(this.form.email)
    },
    async login() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          var response;
          try {
            console.info(`login: request recaptcha - ${this.form.email}`)

            const grecaptcha = await this.$recaptcha('login')
            console.info(`login: request login iniciada - ${this.form.email}`)

            if (!this.isPartner) {
              delete this.form.cnpj
            }

            response = (await Login.login({ ...this.form, grecaptcha }));

            console.info(`login: verificando integridade do retorno - ${this.form.email}`)
            if (response == null || ((response.data == null || response.data.data == null) && !response.data.erros)) {
              this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro na requisição de login' })
              console.error(response);
            }

            console.info(`login: verificando integridade do retorno - ${this.form.email}`)

            console.info(`login: fazendo logout - ${this.form.email}`)

            console.log(response)
            this.$store.dispatch('setLogout')
            console.info(`login: fazendo login - ${this.form.email}`)

            this.$store.dispatch('setLogin', response.data.data)
            console.info(`login: redirecionando para painel - ${this.form.email}`)

            if (response.data.data.roles.includes('socio_tomador') && (this.form.cnpj || this.$route.query.irParaAssinatura)) {
              this.$router.push('/socio/aceitar-condicoes')
            } else {
              if (!response.data.data.roles.includes('tomador')) throw new Error('roleError')
              this.$router.push('/tomador/painel')
            }
            console.info(`login: redirecionado  ${this.form.email}`)
          } catch (error) {
            console.info(`login: entrando catch erro  ${this.form.email}`)
            console.info(`login: error ${JSON.stringify(error)} - ${this.form.email}`)

            this.$store.dispatch('notificationErrors', error.response ? error.response.data.erros : response.data.erros);
            console.log("errr", error)

          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  async mounted() {
    if (this.$route.query.bearerToken) {
      try {
        const response = (await LoginBearer.loginBearer({ bearerToken: this.$route.query.bearerToken })).data.data

        console.log('response bearer => ', response)

        this.$store.dispatch('setLogout')
        this.$store.dispatch('setLogin', response)

        if (response.roles.includes('socio_tomador')) {
          if (this.$route.query.irParaAssinatura) {
            this.$router.push('/socio/aceitar-condicoes')
          } else {
            this.$router.push('/tomador/painel');
          }
        } else {
          if (!response.roles.includes('tomador')) throw new Error('roleError')
          this.$router.push('/tomador/painel')
        }

      } catch (error) {
        this.$router.push('/tomador/login')
      }
    }


    if (this.$route.query.isPartner) {
      this.isPartner = true
    }

    if (this.$route.query.cnpj) {
      this.form.cnpj = this.$route.query.cnpj
      this.$refs.password.$el.focus()

    }

    if (this.$route.query.email) {
      this.form.email = this.$route.query.email
      this.$refs.password.$el.focus()

    }
  }
}
</script>

<style lang="sass" scoped>

.wrapper
  @apply w-screen h-full flex flex-col
  @screen lg
    @apply flex-row h-screen

.image
  @apply h-64 bg-cover bg-no-repeat
  background-image: url("../../assets/images/login/bg-login-business-mobile.png")
  background-position: 0 20%
  @screen lg
    @apply w-1/4 h-full bg-center
    background-image: url("../../assets/images/login/bg-login-business.png")

.article
  @apply hidden
  @screen lg
    @apply w-1/4 h-full bg-primary flex items-center justify-center shadow

.article_content
  @apply mx-8 items-center flex flex-col text-center leading-relaxed

.title
  @apply text-secondary font-bold text-2xl mb-5

.subtitle
  @apply text-white font-bold text-lg

.text
  @apply text-white mb-5 text-sm w-2/3

.form_section
  @apply flex flex-col items-center justify-center
  @screen lg
    @apply w-1/2

.form_content
  @apply flex flex-col items-center
  @screen lg
    @apply my-auto

.logo
  @apply bg-no-repeat h-16 w-40 my-5 cursor-pointer
  background-image: url("../../assets/images/logo.svg")
  @screen lg
    @apply mb-10

.form_title
  @apply text-center mb-5 text-gray-700
  @screen lg
    @apply text-2xl mb-10

.pass_recovery
  @apply mb-4 self-end text-sm text-gray-700 cursor-pointer
  &:hover, &:focus
    @apply text-primary

.footer
  @apply text-center text-xss text-gray-700 leading-relaxed mt-8
  @screen lg
    @apply mb-4 text-xs

</style>
